.App {
  font-family: 'Space Mono', monospace;
  width: 100%;
  height: auto;
}

#light {
  background: #E0DCD9;
  color: #424242;
}

#dark {
background: #424242;
color: #E0DCD9;
}

.btn-section {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}

.btn {
  border: none;
  border-radius: 15px;
}

.icon {
  width: 30px;
}

.nav-link {
  cursor: pointer;
}

a:hover {
  cursor: pointer;
}


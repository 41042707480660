#light {
  background: #e0dcd9;
  color: #424242;

  .vertical-line {
    display: flex;
    height: 60%;
    border: 4px solid #424242;
    margin-right: 20px;
    margin-left: 20px;
  }
}

#dark {
  background: #424242;
  color: #e0dcd9;


  .vertical-line {
    display: flex;
    height: 60%;
    border: 2px solid #e0dcd9;
    margin-right: 20px;
    margin-left: 20px;
  }
}
